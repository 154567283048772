<template>
  <div class="ad-home">
    <ad-header bg="banner1.jpg">
      <div class="banner-text-container">
        <div class="banner-text">
          <div class="en-title">
            <img src="../assets/images/banner-text1.png" />
          </div>
          <div class="line">
            <img src="../assets/images/pic_rectangular.png" />
          </div>
          <p>帮助企业在更大范围寻找合适的人才</p>
        </div>
        <div class="banner-login">
          <ad-login></ad-login>
        </div>
      </div>
    </ad-header>

    <ad-navs type="zp"></ad-navs>

    <div class="advantage-container">
      <div class="title">
        <div class="en-title">
          <img src="../assets/images/advantage 2.png" />
        </div>
        <div class="cn-title">我们的优势</div>
      </div>
      <div class="advantage-list">
        <div class="advantage-item">
          <img src="../assets/images/enterprise/icon_advantage_01.png" />
          <div class="content">
            <h3>更大范围人才搜寻</h3>
            <p>
              90%以上优秀人才不在招聘网站求职，灵鸽帮您链接全网专业猎头的高端人脉圈，帮您全网搜寻优秀人才。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/enterprise/icon_advantage_02.png" />
          <div class="content">
            <h3>更划算的招聘成本</h3>
            <p>
              猎头费用低至年薪3%，用普通招聘渠道的成本，享受专业的猎头服务、优质的招聘体验及高效的招聘交付。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/enterprise/icon_advantage_03.png" />
          <div class="content">
            <h3>更高效的招聘方式</h3>
            <p>
              猎头更懂企业真实的招聘需求，通过猎头筛选意向求职者，精准推荐，减少无效简历，提高人岗匹配率。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/enterprise/icon_advantage_04.png" />
          <div class="content">
            <h3>更多的服务形式</h3>
            <p>
              企业根据岗位实际情况，可以选择意向服务/过保服务，支付方式灵活，不过保不用支付其他成本。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="client-container">
      <div class="client">
        <div class="title">
          <div class="en-title">
            <img src="../assets/images/clients 2.png" />
          </div>
          <div class="cn-title">合作企业</div>
        </div>
        <div class="client-list">
          <div class="client-item">
            <img src="../assets/images/icon_clients_1.png" />
          </div>
          <div class="line"></div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_2.png" />
          </div>
          <div class="line"></div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_3.png" />
          </div>
          <div class="line"></div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_4.png" />
          </div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_5.png" />
          </div>
          <div class="line"></div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_6.png" />
          </div>
          <div class="line"></div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_7.png" />
          </div>
          <div class="line"></div>
          <div class="client-item">
            <img src="../assets/images/icon_clients_8.png" />
          </div>
        </div>
      </div>
    </div>

    <ad-footer style="margin-top: 130px"></ad-footer>
  </div>
</template>

<script>
import AdLoginComponent from "./login.vue";
export default {
  name: "AdEnterpriseHomeComponent",
  components: {
    "ad-login": AdLoginComponent,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.ad-home {
  width: 100%;
  box-sizing: border-box;

  .banner-text-container {
    max-width: 1180px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    .banner-text {
      width: 686px;
      flex: 1;
      padding-top: 239px;
      .line {
        margin: 59px 0px 60px 0px;
      }
      p {
        font-size: 26px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
    .banner-login {
      position: relative;
      padding-top: 228px;
    }
  }

  .advantage-container {
    width: 100%;
    height: 760px;
    box-sizing: border-box;
    background: #f6f9fa url("../assets/images/icon-bg1.png") no-repeat center;
    background-size: cover;
    padding-top: 100px;
    .title {
      width: 100%;
      text-align: center;
      .en-title {
        img {
          width: 173px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-family: Noto Sans SC;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .advantage-list {
      display: flex;
      align-items: flex-start;
      max-width: 1180px;
      margin: 80px auto auto auto;
      .advantage-item {
        width: 268px;
        height: 320px;
        box-sizing: border-box;
        padding: 18px;

        background: #ffffff;
        box-shadow: 0px 30px 28px 0px rgba(235, 241, 244, 0.66);
        border-radius: 20px;

        margin-left: 36px;

        &:first-child {
          margin-left: 0px;
        }

        img {
          width: 100px;
          height: 118px;
        }

        .content {
          h3 {
            font-size: 16px;
            font-family: Noto Sans SC;
            font-weight: 500;
            color: #222222;
            margin-top: 36px;
          }
          p {
            font-size: 14px;
            font-family: Noto Sans SC;
            font-weight: 400;
            color: #454545;
            line-height: 20px;
            margin-top: 16px;
          }
        }
      }
    }
  }

  .client-container {
    box-sizing: border-box;
    width: 100%;
    margin: 0px auto;
    background: #f6f9fa;

    .client {
      box-sizing: border-box;
      max-width: 1630px;
      height: 806px;
      margin: 0px auto;
      background: url("../assets/images/icon-bg2.png") no-repeat center;
      padding-top: 100px;
      .title {
        width: 100%;
        text-align: center;
        .en-title {
          img {
            width: 120px;
            height: 30px;
          }
        }
        .cn-title {
          font-size: 20px;
          font-family: Noto Sans SC;
          font-weight: 600;
          color: #111111;
          margin-top: 18px;
        }
      }
      .client-list {
        max-width: 1180px;
        margin: 60px auto auto auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .client-item {
          width: 268px;
          height: 194px;
          line-height: 194px;
          background: #ffffff;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 30px;
          img {
            width: 180px;
            height: 90px;
            margin-top: 52px;
          }
        }
        .line {
          width: 36px;
        }
      }
    }
  }

  .job-container {
    width: 100%;
    box-sizing: border-box;
    padding-top: 100px;
    .title {
      text-align: center;
      .en-title {
        img {
          width: 195px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-family: Noto Sans SC;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .job-list {
      max-width: 1180px;
      box-sizing: border-box;
      margin: 60px auto auto auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .job-item {
        width: 572px;
        height: 174px;
        box-sizing: border-box;
        border-radius: 20px;
        border: 1px solid #e6e6e6;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 36px 42px 38px 42px;
        &:nth-child(odd) {
          margin-right: 32px;
        }
        &:hover {
          background: #ffffff;
          box-shadow: 0px 24px 30px 0px rgba(235, 241, 244, 0.6);
          border: none;
          .icon {
            display: block !important;
          }
        }
        .jon-name {
          display: flex;
          align-items: center;
          h2 {
            flex: 1;
            font-size: 16px;
            font-family: Noto Sans SC;
            font-weight: 600;
            color: #111111;
          }
          p {
            font-size: 20px;
            font-family: Noto Sans SC;
            font-weight: 900;
            color: #222222;
          }
        }
        .desc {
          font-size: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          color: #999999;
          margin-top: 14px;
        }
        .company {
          display: flex;
          align-items: center;
          margin-top: 42px;
          .name {
            flex: 1;
            font-size: 14px;
            font-family: Noto Sans SC;
            font-weight: 400;
            color: #222222;
          }
          .icon {
            display: none;
            img {
              width: 18px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
</style>